import { useEffect, useState } from 'react'
import { GetServerSidePropsContext } from 'next'
import Image from 'next/image'
import useSWR from 'swr'
import styles from './index.module.scss'

import GreenArrowDown from '../../public/svgs/green-arrow-down.svg'

import TrialHeaderCountdown from '../../layouts/TrialHeaderCountdown'
import { remainingTimeForCountdown } from '../../utils/remainingTimeForCountdown'
import TrialHeader from '../../layouts/TrialHeader'
import { LessonListContent } from '../../components/LessonListContent'
import { TrialLessonContent } from '../../components/TrialLessonContent'
import { AwardContent } from '../../components/AwardContent'

import { ContentType } from '../../types/trialLessons'

const fetcher = (url: string) =>
  fetch(url, { credentials: 'include' }).then(res => res.json())

const fetcherAll = (...urls: [string]) =>
  Promise.all(urls.map((url: string) => fetcher(url)))

type Props = { contentType: ContentType }

const TrialLessons = ({ contentType }: Props) => {
  const [jwtToken, setJwtToken] = useState('')
  const [currentUserEmail, setCurrentUserEmail] = useState<string | null>(null)
  const [currentUserBirthday, setCurrentUserBirthday] = useState<string | null>(
    null
  )
  const [currentUserPrefecture, setCurrentUserPrefecure] = useState<
    string | null
  >(null)
  const [isCountdownActive, setIsCountdownActive] = useState<boolean>(false)

  // 現在のユーザー情報を取得
  const { data: currentUserAndTrialLesson, error } = useSWR(
    [`${process.env.NEXT_PUBLIC_API_URL}/api/v1/users/current`],
    fetcherAll
  )

  const { data: jwt } = useSWR(
    `${process.env.NEXT_PUBLIC_API_URL}/api/v3/users/current/jwt`,
    fetcher
  )

  useEffect(() => {
    setIsCountdownActive(remainingTimeForCountdown().milliSeconds >= 0)
    const countdownInterval = setInterval(() => {
      setIsCountdownActive(remainingTimeForCountdown().milliSeconds >= 0)
    }, 1000)
    return () => clearInterval(countdownInterval)
  }, [remainingTimeForCountdown()])

  useEffect(() => {
    if (jwt && !jwt.error) {
      setJwtToken(jwt.body)
    }
  }, [jwt])

  useEffect(() => {
    currentUserAndTrialLesson?.forEach(v => {
      if (v?.email) {
        setCurrentUserEmail(v.email)
      }
      if (v?.birthday) {
        setCurrentUserBirthday(v.birthday)
      }
      if (v?.prefecture) {
        setCurrentUserPrefecure(v.prefecture)
      }
    })
  }, [currentUserAndTrialLesson])

  return (
    <>
      <div className={styles.container}>
        {isCountdownActive && <TrialHeaderCountdown />}
        <TrialHeader
          contentType={contentType}
          isCountdownActive={isCountdownActive}
        />
        <AwardContent contentType={contentType} />
        <TrialLessonContent contentType={contentType} />
        <section
          className={styles.applyContainer}
          id="reservation-step-summary"
        >
          <LessonListContent
            contentType={contentType}
            jwtToken={jwtToken}
            currentUserEmail={currentUserEmail}
            currentUserBirthday={currentUserBirthday}
            currentUserPrefecture={currentUserPrefecture}
          />
        </section>
      </div>
    </>
  )
}

export const getServerSideProps = async ({
  query,
}: GetServerSidePropsContext) => {
  const { utm_campaign, utm_term, type } = query
  let contentType = 'onlyOtl'
  const onsParams = [utm_campaign, utm_term, type]
  if (onsParams.some(p => p?.includes('_ons'))) {
    contentType = 'recommendOns'
  }

  return {
    props: { contentType },
  }
}

export default TrialLessons
