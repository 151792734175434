import { useEffect, useLayoutEffect, useState } from 'react'
import styles from './index.module.scss'
import CampaignCountdownMessagePc from '../../public/campaign_countdown_message_pc.png'
import CampaignCountdownMessageSp from '../../public/campaign_countdown_message_sp.png'
import {
  RemainingTime,
  remainingTimeForCountdown,
} from '../../utils/remainingTimeForCountdown'

const TrialHeaderCountdown = () => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
  const [remainingTime, setRemainingTime] = useState<RemainingTime>({
    milliSeconds: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  const isMobile = windowWidth <= 450

  const scrollToAwardContent = () => {
    const anchor = document.getElementById('award-content')
    anchor?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth)
  }, [window.innerWidth])

  useLayoutEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(remainingTimeForCountdown())
    }, 1000)

    return () => clearInterval(timer)
  }, [remainingTimeForCountdown()])

  return (
    <div className={styles.headerCountdown} id="trial-header-countdown">
      <img
        className={styles.countdownMessage}
        src={
          isMobile
            ? CampaignCountdownMessageSp.src
            : CampaignCountdownMessagePc.src
        }
        alt="カウントダウンメッセージ"
      />
      <div className={styles.countdownTimer}>
        <p className={styles.countdownTextTime}>
          <span>{remainingTime.days.toString().padStart(2, '0')[0]}</span>
          <span>{remainingTime.days.toString().padStart(2, '0')[1]}</span>
        </p>
        <p className={styles.countdownTextUnit}>
          <span>日</span>
        </p>
        <p className={styles.countdownTextTime}>
          <span>{remainingTime.hours.toString().padStart(2, '0')[0]}</span>
          <span>{remainingTime.hours.toString().padStart(2, '0')[1]}</span>
        </p>
        <p className={styles.countdownTextUnit}>
          <span>時間</span>
        </p>
        <p className={styles.countdownTextTime}>
          <span>{remainingTime.minutes.toString().padStart(2, '0')[0]}</span>
          <span>{remainingTime.minutes.toString().padStart(2, '0')[1]}</span>
        </p>
        <p className={styles.countdownTextUnit}>
          <span>分</span>
        </p>
        <p className={styles.countdownTextTime}>
          <span>{remainingTime.seconds.toString().padStart(2, '0')[0]}</span>
          <span>{remainingTime.seconds.toString().padStart(2, '0')[1]}</span>
        </p>
        <p className={styles.countdownTextUnit}>
          <span>秒</span>
        </p>
      </div>
    </div>
  )
}

export default TrialHeaderCountdown
